@use 'variables';

a {
  color: variables.$blue;
  text-decoration: underline;
  &:hover {
    color: variables.$blue;
    background-color: variables.$orange;
    opacity: 1;
  }
}
