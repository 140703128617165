@use 'src/variables';

.our-partners {
  background-color: variables.$lilac;
  color: variables.$blue;
  padding: 80px 0;
  .background-image {
    position: absolute;
    z-index: 0;
  }
  .our-partners-laptop {
    width: 300px;
    right: 15px;
    top: 20px;
    @media screen and (min-width: variables.$min-width-lg) {
      max-width: 400px;
      right: 10%;
      top: 50px;
    }
  }
  .our-partners-hand {
    top: -100px;
    max-width: 200px;
    right: 0;
    opacity: 0;
    @media screen and (min-width: variables.$min-width-lg) {
      opacity: 1;
      max-width: 400px;
      right: 0;
      top: 50px;
    }
  }
  .col-lg-6 {
    margin-bottom: 50px;
  }
  .col-md-4 {
    margin-bottom: 30px;
  }
  .logo {
    background-color: variables.$blue;
    padding: 40px;
    height: 280px;
    display: flex;
    margin: 30px 0;
    &.partners {
      height: 240px;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .charity-partners {
    border-top: solid 3px variables.$orange;
    padding: 50px 0 0;
    margin-top: 100px;
  }
  .charity-table {
    border-top: solid 1.5px variables.$blue;
    padding: 15px 0;
  }
}
