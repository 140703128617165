@use 'src/variables';

.instagram-icon {
  display: block;
  height: 40px;
  width: 40px;
  background-image: url("/img/iconmonstr-instagram-11.svg");
  position: relative;
  margin: auto;
  margin-bottom: -50px;
  @media screen and (min-width: variables.$min-width-lg) {
    max-height: 40px;
  }

  a:before {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    background-image: url("/img/iconmonstr-instagram-11-hover.svg");
    position: absolute;
    top: 0;
    opacity: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    @media screen and (min-width: variables.$min-width-lg) {
      height: 40px;
    }
  }

  a:hover:before {
    opacity: 1;
  }
}
