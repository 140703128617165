@use 'src/variables';

.support-us {
  background-color: variables.$dark-blue;
  padding-top: 80px;
  .button {
    margin-top: 50px;
    @media (min-width: variables.$min-width-lg) {
      padding: 60px 0 0;
    }
  }

  .background-image {
    position: absolute;
    z-index: 0;
  }
}
