@use 'src/variables';

.button {
  display: block;
  width: 350px;
  height: 100px;
  background-image: url("/img/IT_Poverty__ELP_Button.svg");
  position: relative;
  margin: auto;
  margin-bottom: 50px;
  @media screen and (min-width: variables.$min-width-lg) {
    width: 500px;
    height: 150px;
  }

  a:after {
    content: "";
    display: block;
    width: 350px;
    height: 100px;
    background-image: url("/img/IT_Poverty__ELP_Button_Click.svg");
    position: absolute;
    top: 0;
    opacity: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    @media screen and (min-width: variables.$min-width-lg) {
      width: 500px;
      height: 150px;
    }
  }

  a:before {
    content: "";
    display: block;
    width: 350px;
    height: 100px;
    background-image: url("/img/IT_Poverty__ELP_Button_Hover.svg");
    position: absolute;
    top: 0;
    opacity: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    @media screen and (min-width: variables.$min-width-lg) {
      width: 500px;
      height: 150px;
    }
  }

  a:hover:before {
    opacity: 1;
  }

  a:active:before {
    opacity: 0;
  }

  a:active:after {
    opacity: 1;
  }
}
