@use 'variables';
@use 'typography';

html {
  overflow-x: hidden;
}

body {
  background-color: variables.$blue;
  color: variables.$lilac;
  font-family: variables.$font-stack;
}

*::-moz-selection {
  background-color: variables.$orange;
  opacity: 1;
}
*::selection {
  background-color: variables.$orange;
  opacity: 1;
}

section {
  position: relative;
}

.orange {
  color: variables.$orange;
}
