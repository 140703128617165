$blue: #001eff;
$dark-blue: #001ec8;
$lilac: #cdbef0;
$orange: #ff5a00;

$font-stack: "MAD Sans Fill", Helvetica, sans-serif;

$min-width-sm: 576px;
$min-width-md: 768px;
$min-width-lg: 992px;
$min-width-xl: 1200px;
