@use 'src/variables';

.hero {
  min-height: calc(100vh - 106px);
  z-index: 1;
  h1 {
    visibility: hidden;
    position: relative;
    top: -40px;
    @media (min-width: variables.$min-width-lg) {
      font-size: 80px;
      top: -90px;
      @media (max-height: 800px) {
        font-size: 60px;
        top: -60px;
      }
    }
  }
}
