@use 'src/variables';
@use 'src/typography';
@use 'src/base';
@use 'src/links';
@use 'src/button';
@use 'src/instagram-icon';
/* SECTION STYLES */
@use 'src/hero';
@use 'src/theproblem';
@use 'src/thesolution';
@use 'src/ourpartners';
@use 'src/specs';
@use 'src/supportus';
@use 'src/footer';
