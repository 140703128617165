@use 'src/variables';

.the-problem {
  background-color: variables.$dark-blue;
  min-height: 800px;
  padding-top: 80px;
  @media screen and (min-width: variables.$min-width-lg) {
    min-height: 90vh;
    padding-top: 300px;
    @media (max-height: 800px) {
      padding-top: 150px;
      min-height: 120vh;
    }
  }
  .background-image {
    position: absolute;
    z-index: 0;
  }
  .laptop {
    max-width: 800px;
    bottom: 15%;
    left: 0;
    @media screen and (min-width: variables.$min-width-lg) {
      right: 15%;
      left: auto;
    }
  }

  .hand {
    top: auto;
    bottom: -6px;
    right: 20%;
    width: 110px;
    margin: 0;
    @media screen and (min-width: variables.$min-width-lg) {
      right: 30%;
      width: 190px;
      @media (max-height: 800px) {
        right: 20%;
      }
    }
  }
}
